import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Slide from "@mui/material/Slide";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { SubNavLinkItem } from "../SubNavigationBar";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import classnames from "classnames";
import { Hidden, Link } from "@mui/material";
import { HeaderSubNavLinksCard } from "../HeaderSubNavLinksCard";
import { isAWhitelistedBot } from "../../Utils";
import { HeaderLinkBase } from "../Header";
import { TabbedNav, TabbedNavLink } from "../TabbedNav";

export type SlidingMobileMenuProps = {
  links: SubNavLinkItem[];
  Buttons?: React.FC;
  open: boolean;
  locationMap?: JSX.Element;
  setOpen: (open: boolean) => void;
  locationMapLabel: string;
  locationListLabel: string;
  viewAllLocation?: {
    label: string;
    url: string;
  };
  fullWidthSideMenu?: boolean;
};

type VisibleItem = {
  prev?: SubNavLinkItem;
  next?: SubNavLinkItem;
};

enum MapTab {
  List = "List",
  Map = "Map",
  England = "England",
  location = "Locations",
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    "& $paperRoot": {
      minWidth: 400,
      [theme.breakpoints.down("md")]: {
        minWidth: "80%",
      },
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    "& $cardHeaderRoot": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1.6, 5),
      minHeight: theme.spacing(6),
    },
    "& $cardAction": {
      position: "absolute",
      left: "100%",
      marginLeft: -53,
      marginTop: -3,
    },
  },
  fullWidthSideMenu: {
    "& $paperRoot": {
      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
      },
      borderRadius: 0,
    },
  },
  mobileDrawerContent: {
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    "& $cardRoot": {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.background.default,
    },
    "& $paperRoot": {
      backgroundColor: "transparent",
      overflowY: "hidden",
    },
    "& $cardAction": {
      marginTop: "auto",
    },
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
    padding: theme.spacing(0.3),
    minWidth: "auto",
    margin: theme.spacing(0, 0, 0, 1),
    "&:hover": {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  cardContentContainer: {
    position: "relative",
    overflow: "hidden",
    height: "100%",
  },
  drilledInCardContent: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    overflowY: "auto",
    overflowX: "hidden",
    padding: 0,
    width: "100%",
    height: "100%",
    "&:last-child": {
      padding: 0,
    },
  },
  linkItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& a:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
      padding: theme.spacing(2, 3),
      fontFamily: theme.typography.h2.fontFamily,
      fontWeight: 600,
    },
  },
  linkItemBack: {
    background: `linear-gradient(to top, ${theme.palette.divider} 0%, #fcfcfc 100%)`,
    position: "relative",
    "& svg": {
      position: "absolute",
      marginLeft: theme.spacing(-2.5),
    },
  },
  drilledLink: {
    "& a": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
    },
  },
  ssr: {
    display: "none",
  },
  locationTabs: {
    paddingTop: theme.spacing(1.5),

    "& button": {
      [theme.breakpoints.down("xl")]: {
        padding: theme.spacing(0.5, 1.5),
        width: "35%",
      },
    },
  },
  viewAllLocations: {
    padding: theme.spacing(2, 2, 1.5, 2),
  },
  locationMapView: {
    "& >div": {
      padding: 0,
      margin: 0,
      "& >div": {
        borderRadius: 0,
        "& >div:first-child": {
          [theme.breakpoints.down("lg")]: {
            height: "calc(100vh - 186px) !important",
          },
        },
      },
    },
  },
  paperRoot: {},
  cardHeaderRoot: {},
  cardAction: {},
}));

export type ViewAllButtonProps = {
  title: string;
  description?: string;
  isActive?: boolean;
  link?: {
    isActive?: boolean;
    link: string;
    text: string;
  }[];
  text?: string;
  links: HeaderLinkBase[];
};

export const SlidingMobileMenu: React.FC<SlidingMobileMenuProps> = ({
  links,
  Buttons,
  open,
  setOpen,
  locationMap,
  locationMapLabel,
  locationListLabel,
  viewAllLocation,
  fullWidthSideMenu,
}) => {
  const classes = useStyles();

  const [drillIn, setDrillIn] = useState(false);
  const [menuTrack, setMenuTrack] = useState<SubNavLinkItem[]>([
    {
      text: "",
      link: links,
      isActive: false,
    },
  ]);
  const [visibleItem, setVisibleItem] = useState<VisibleItem>({
    prev: menuTrack[0],
    next: menuTrack[0],
  });
  const [slideIn, setSlideIn] = useState(true);

  const renderListItem = (item: SubNavLinkItem) => {
    const link = item.link;
    if (typeof link === "string") {
      return (
        <a href={link} data-testid="subNavBarLink">
          {item.text}
        </a>
      );
    } else if (typeof link === "function") {
      return (
        <a
          href="javascript:"
          data-testid="subNavBarLink"
          onClick={(e) => {
            e.preventDefault();
            link();
          }}
        >
          {item.text}
        </a>
      );
    } else if (link) {
      return (
        <a
          href="javascript:"
          data-testid="subNavBarLink"
          onClick={(e) => {
            e.preventDefault();
            typeof item.link !== "string" && nextMenuItem(item);
          }}
        >
          {item.text} <ChevronRightIcon />
        </a>
      );
    }
  };
  const viewAllButtonProps: ViewAllButtonProps = {
    text: "",
    title: "",
    links: [],
    description: "",
    isActive: false,
    link: [
      {
        isActive: false,
        link: viewAllLocation?.url as string,
        text: viewAllLocation?.label as string,
      },
    ],
  };
  const renderMenu = (menuItem: SubNavLinkItem) => {
    return (
      <>
        {menuItem.text && !menuItem?.isMobileTabbedContent ? (
          <Typography
            className={classNames(classes.linkItem, classes.linkItemBack)}
          >
            <b>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  prevMenuItem();
                }}
              >
                {menuItem.text} <ChevronLeftIcon />
              </a>
            </b>
          </Typography>
        ) : (
          <>
            {menuItem.text && (
              <>
                <Typography
                  className={classNames(classes.linkItem, classes.linkItemBack)}
                >
                  <b>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        prevMenuItem();
                      }}
                    >
                      {menuItem.text} <ChevronLeftIcon />
                    </a>
                  </b>
                </Typography>
                {menuItem.isMobileTabbedContent && (
                  <Hidden lgUp>
                    <div className={classes.locationTabs}>
                      <TabbedNav links={tabs} mobileText={""} />
                    </div>
                  </Hidden>
                )}

                {!mapTabActive ? (
                  <>
                    {menuItem?.mobileCard &&
                      menuItem?.mobileCard.length > 0 && (
                        <Typography className={classes.viewAllLocations}>
                          <Link
                            href={menuItem?.mobileCard[0].link as string}
                            color={"secondary"}
                            underline="none"
                          >
                            <b>{menuItem?.mobileCard[0].text}</b>
                          </Link>
                        </Typography>
                      )}
                    {menuItem.isMobileTabbedContent &&
                      menuItem.link &&
                      menuItem.link.length > 0 && (
                        <>
                          <Grid>
                            <HeaderSubNavLinksCard
                              subnavLinks={viewAllButtonProps}
                            />
                          </Grid>
                          {(menuItem.link as SubNavLinkItem[]).map(
                            (subnav: any, index: number) => (
                              <Grid key={`subnav-item-${index}`} item>
                                {!!subnav && (
                                  <HeaderSubNavLinksCard
                                    index={`subNav-${index}`}
                                    subnavLinks={subnav}
                                  />
                                )}
                              </Grid>
                            ),
                          )}
                        </>
                      )}
                  </>
                ) : (
                  <div className={classes.locationMapView}>{locationMap}</div>
                )}
              </>
            )}
          </>
        )}

        {typeof menuItem.link !== "string" &&
          typeof menuItem.link !== "function" &&
          !menuItem.isMobileTabbedContent &&
          menuItem.link.map((item, i) => (
            <Typography
              key={i}
              className={classNames(classes.linkItem, {
                [classes.drilledLink]: menuTrack.length > 1,
              })}
            >
              {renderListItem(item)}
            </Typography>
          ))}
      </>
    );
  };

  const prevMenuItem = () => {
    if (slideIn) setSlideIn(false);

    setDrillIn(!drillIn);
    setVisibleItem({
      prev: menuTrack[menuTrack.length - 1],
      next: menuTrack[menuTrack.length - 2],
    });
    setMenuTrack([...menuTrack.slice(0, -1)]);
  };

  const nextMenuItem = (menuItem: SubNavLinkItem) => {
    if (!slideIn) setSlideIn(true);

    setDrillIn(!drillIn);
    setVisibleItem({
      prev: menuTrack[menuTrack.length - 1],
      next: menuItem,
    });
    setMenuTrack([...menuTrack, menuItem]);
  };

  const handleSlideDirection = (drillIn: boolean): "left" | "right" => {
    if (slideIn) {
      return drillIn ? "left" : "right";
    } else {
      return drillIn ? "right" : "left";
    }
  };

  const displayMenu = (visible: boolean) => {
    return (
      visibleItem.prev &&
      visibleItem.next &&
      renderMenu(visible ? visibleItem.next : visibleItem.prev)
    );
  };

  const makeInitialTabs = (): TabbedNavLink[] => [
    {
      primaryLabel: locationListLabel,
      active: true,
      onTabClick: () => handleTabChange(0),
    },
    {
      primaryLabel: locationMapLabel,
      active: false,
      onTabClick: () => handleTabChange(1),
    },
  ];
  const [tabs, setTabs] = useState<TabbedNavLink[]>(makeInitialTabs);
  const handleTabChange = (index: number) => {
    setTabs(
      tabs.map((tab, i) =>
        index === i
          ? {
              ...tab,
              active: true,
            }
          : {
              ...tab,
              active: false,
            },
      ),
    );
  };
  const mapTabActive = tabs[1].active;

  return (
    <SwipeableDrawer
      className={classnames(classes.mobileDrawer, {
        [classes.ssr]: isAWhitelistedBot(),
        [classes.fullWidthSideMenu]: fullWidthSideMenu,
      })}
      anchor="right"
      open={isAWhitelistedBot() ? true : open}
      onOpen={() => {}}
      hysteresis={0.1}
      minFlingVelocity={200}
      transitionDuration={150}
      disableSwipeToOpen
      onClose={() => setOpen(false)}
      data-testid="slidingMobileMenu"
      classes={{
        paper: classes.paperRoot,
      }}
    >
      <div className={classes.mobileDrawerContent}>
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
              action: classes.cardAction,
            }}
            action={
              <Button
                color="primary"
                variant="outlined"
                className={classes.closeButton}
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </Button>
            }
          />
          <div className={classes.cardContentContainer}>
            <Slide
              in={!drillIn}
              direction={handleSlideDirection(!drillIn)}
              appear={false}
            >
              <div className={classes.drilledInCardContent}>
                <CardContent className={classes.cardContent}>
                  {displayMenu(!drillIn)}
                </CardContent>
              </div>
            </Slide>

            <Slide
              in={drillIn}
              direction={handleSlideDirection(drillIn)}
              appear={false}
            >
              <div className={classes.drilledInCardContent}>
                <CardContent className={classes.cardContent}>
                  {displayMenu(drillIn)}
                </CardContent>
              </div>
            </Slide>
          </div>
          {Buttons && (
            <CardActions>
              <Grid item container spacing={2}>
                <Buttons />
              </Grid>
            </CardActions>
          )}
        </Card>
      </div>
    </SwipeableDrawer>
  );
};
